/* alex-brush-regular - latin */
@font-face {
  font-family: 'Alex Brush';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/alex-brush-v20-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/alex-brush-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/alex-brush-v20-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/alex-brush-v20-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/alex-brush-v20-latin-ext_latin-regular.svg') format('svg'); /* Legacy iOS */
}